<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <b-row class="mb-2">
        <b-col md="12">
          <h5 class="text-uppercase heading">Book Cottages</h5>
        </b-col>
      </b-row>

      <form>
        <b-row>
          <b-col md="3">
            <label class="text-muted">Select Farm Retreat</label>
            <b-form-select
              required
              v-model="form.projectID"
              @change="FarmRetreatChange"
            >
              <b-form-select-option value=""
                >Select Farm Retreat</b-form-select-option
              >
              <b-form-select-option
                :value="project._id"
                v-for="(project, index) in Projects"
                :key="index"
                >{{ project._projectID.name }}</b-form-select-option
              >
            </b-form-select>
          </b-col>
          <b-col md="3">
            <label class="text-muted">Select Cottage</label>
            <b-form-select
              required
              v-model="form.projectCottageId"
              @change="SetCottageCount"
            >
              <b-form-select-option value=""
                >Select Cottage</b-form-select-option
              >
              <b-form-select-option
                :value="cottage._id"
                v-for="(cottage, index) in ProjectCottage"
                :key="index"
                >{{ cottage.name }}</b-form-select-option
              >
            </b-form-select>
          </b-col>
          <b-col md="3">
            <label class="text-muted">Count of Cottage</label>
            <b-form-select
              required
              v-model="form.Room"
              :options="RoomOptions"
              @change="setAdultCount"
            >
            </b-form-select>
          </b-col>
          <b-col md="3">
            <label class="text-muted">Select Adults</label>
            <b-form-select
              @change="AdultChange"
              v-model="form.Adults"
              :options="AdultOptions"
              required
            >
            </b-form-select>
          </b-col>
          <b-col md="4" class="mt-3">
            <label class="text-muted">Select Child</label>
            <b-form-select
              required
              v-model="form.Child"
              :options="ChildOptions"
            >
            </b-form-select>
          </b-col>

          <b-col md="4" class="mt-3">
            <ValidationProvider rules="required" v-slot="v">
              <label class="text-muted">Select Check IN Date</label>
              <b-form-datepicker
                placeholder="Check-in date"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  date: '2-digit',
                }"
                :date-disabled-fn="dateDisabledCheckInDate"
                v-model="form.CheckInDate"
                @input="setCheckOutDate()"
                class="mb-2"
              ></b-form-datepicker>
              <span>{{ v.errors[0] }}</span>
            </ValidationProvider>
          </b-col>
          <b-col md="4" class="mt-3">
            <label class="text-muted">Select Check Out Date</label>
            <b-form-datepicker
              placeholder="Check-out date"
              :date-disabled-fn="dateDisabledCheckOutDate"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              id="example-datepicker1"
              :input-attr="{ required: 'true' }"
              v-model="form.CheckOutDate"
              class="mb-2"
              required
            ></b-form-datepicker>
          </b-col>
          <b-col md="3"></b-col>
          <b-col md="6" class="mt-3 mb-3 text-center">
            <b-button
              @click="getProjectResult"
              :disabled="isDisable"
              block
              variant="success"
            >
              Search
            </b-button>
          </b-col>
          <b-col md="3"></b-col>
        </b-row>
      </form>

      <b-table
        id="my-table"
        class="dataTable rounded-left mt-2"
        :outlined="true"
        :items="data"
        :fields="fields"
        head-variant="none"
        table-variant="none"
        :caption-top="true"
        :busy="isBusy"
        show-empty
      >
        <template #empty="scope">
          <h4>{{ scope.emptyText }}</h4>
        </template>
        <template #table-caption>Available Cottages</template>
        <template #cell(#)="data">{{ data.index + 1 }}</template>
        <template #cell(image)="row">
          <img
            :src="row.item._projectID.image"
            alt="asset image"
            height="50px"
            width="50px"
            style="border-radius: 10px"
          />
        </template>
        <template #cell(actions)="row">
          <div class="d-inline" @click="showRoomModal(row.item, row.index)">
            <i
              class="fas fa-eye view-icon"
              style="cursor: pointer"
              aria-hidden="true"
            ></i>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>

      <div>
        <b-modal
          ref="modal-view"
          @hidden="close"
          title="Cottage(s) Details"
          size="xl"
          hide-footer
        >
          <form>
            <div class="container">
              <div class="row">
                <div class="col-md-2">
                  <label>Select Cottage</label>
                  <b-form-select
                    v-model="form.Room"
                    disabled
                    :options="RoomOptions"
                    required
                  >
                  </b-form-select>
                </div>
                <div class="col-md-2">
                  <label>Select Adults</label>
                  <b-form-select
                    disabled
                    v-model="form.Adults"
                    :options="AdultOptions"
                    required
                  >
                  </b-form-select>
                </div>
                <div class="col-md-2">
                  <label class="font-weight-bold">Child</label>

                  <b-form-select
                    disabled
                    v-model="form.Child"
                    :options="ChildOptions"
                    required
                  ></b-form-select>
                </div>
                <div class="col-md-3">
                  <label>Select Check IN Date</label>
                  <b-form-datepicker
                    id="example-datepicker"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      date: '2-digit',
                    }"
                    readonly
                    v-model="form.CheckInDate"
                    class="mb-2"
                    required
                  ></b-form-datepicker>
                </div>
                <div class="col-md-3">
                  <label>Select Check Out Date</label>
                  <b-form-datepicker
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      date: '2-digit',
                    }"
                    id="example-datepicker"
                    readonly
                    v-model="form.CheckOutDate"
                    class="mb-2"
                    required
                  ></b-form-datepicker>
                </div>
              </div>
            </div>
          </form>
          <b-tabs card>
            <b-tab title="Cottage(s) Available" active>
              <b-card-text>
                <template>
                  <table
                    class="table table-sm table-bordered"
                    v-if="booking.step == 1"
                  >
                    <thead class="thead">
                      <tr>
                        <th>#</th>
                        <th>Cottage(s) Name</th>
                        <th>Availability</th>
                        <th>Credits</th>
                        <th>Service Charge/Day</th>
                        <th width="10%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(r, i) in rooms" :key="i">
                        <th>{{ 1 + i }}</th>
                        <td v-if="r">{{ r.name }}</td>
                        <td v-else></td>
                        <td v-if="r">{{ r.room_counts }}</td>
                        <td v-else></td>
                        <td>
                          <table class="table">
                            <tbody>
                              <tr
                                v-for="(credit, index) in r.credits"
                                :key="index"
                              >
                                <th>
                                  <span v-if="credit.type == 1"
                                    >Weekends -
                                  </span>
                                  <span v-if="credit.type == 2"
                                    >Weekdays -
                                  </span>
                                  <span v-if="credit.type == 3"
                                    >Holidays -
                                  </span>
                                </th>
                                <td>{{ credit.points }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>
                          {{ r.service_charges }}
                        </td>

                        <td>
                          <div
                            class="btn-group"
                            v-if="r.room_counts >= form.Room"
                          >
                            <button
                              class="btn btn-sm btn-success"
                              @click="roomSeletected(r)"
                              type="button"
                            >
                              Book
                            </button>
                          </div>
                          <div v-else>
                            <strong>No cottages available</strong>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
                <template v-if="booking.step == 2">
                  <button
                    class="btn btn-sm btn-danger mb-3 pl-2 pr-2"
                    style="border-radius: 15px"
                    @click="booking.step = 1"
                    type="button"
                  >
                    <i class="fas fa-arrow-left"></i> Back
                  </button>
                  <table class="table table-sm table-bordered">
                    <thead class="thead">
                      <tr style="font-size: 18px">
                        <th width="50%">Details</th>
                        <th width="50%">Info</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Member</th>
                        <td>
                          <v-select
                            @input="setSelected"
                            :options="users"
                            v-model="booking.user"
                            label="name"
                          >
                            <template v-slot:option="option">
                              <span :class="option.icon"></span>
                              {{ option.name }} {{ option.phone }}
                            </template>
                          </v-select>
                        </td>
                      </tr>
                      <tr class="bg-success text-white" v-if="booking.user">
                        <th>Available Credits</th>
                        <td>
                          {{ parseFloat(booking.availableCredits).toFixed(2) }}
                          Credit(s)
                        </td>
                      </tr>
                      <tr>
                        <th>Price</th>
                        <td>{{ booking.roomCredits }} Credit(s)</td>
                      </tr>
                      <tr>
                        <th>Service Charge</th>
                        <td>{{ booking.service_charge }}</td>
                      </tr>
                      <tr>
                        <th>Total Day & Night</th>
                        <td>{{ booking.days }} D & {{ booking.nights }}N</td>
                      </tr>
                      <tr>
                        <th>Check In Date</th>
                        <td>
                          {{ booking.query.CheckInDate | moment('DD/MM/YYYY') }}
                        </td>
                      </tr>
                      <tr>
                        <th>Check Out Date</th>
                        <td>
                          {{
                            booking.query.CheckOutDate | moment('DD/MM/YYYY')
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th>Cottage Details</th>
                        <td v-if="booking.roomDetails">
                          {{ booking.roomDetails.name }}
                        </td>
                        <td v-else></td>
                      </tr>
                      <tr>
                        <th>Remarks</th>
                        <td>
                          <textarea
                            class="form-control"
                            placeholder="Enter Remarks"
                            v-model="booking.remarks"
                            cols="30"
                            rows="5"
                          ></textarea>
                        </td>
                      </tr>
                      <tr>
                        <th
                          colspan="3"
                          v-if="
                            parseFloat(booking.availableCredits) >=
                              parseFloat(booking.roomCredits) && booking.user
                          "
                        >
                          <button
                            class="btn btn-sm btn-success mb-3 pl-2 pr-2"
                            style="border-radius: 15px"
                            @click="bookRoom"
                            :disabled="disableBookButton"
                          >
                            Book
                          </button>
                        </th>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </template>
              </b-card-text>
            </b-tab>
            <b-tab title="Cottage(s) Bookings">
              <b-card-text>
                <table class="table table-sm table-bordered ">
                  <thead class="thead">
                    <tr>
                      <th>#</th>

                      <th>Check In</th>
                      <th>Check Out</th>
                      <th>Member</th>
                      <th>Count</th>
                      <th>Adult & Child</th>
                      <th>Status</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(b, i) in bookings" :key="i">
                      <td>{{ 1 + i }}</td>

                      <td>
                        {{
                          b.bookingID.checkInDate | moment('dddd, MMMM Do YYYY')
                        }}
                      </td>

                      <td>
                        {{
                          b.bookingID.checkOutDate
                            | moment('dddd, MMMM Do YYYY')
                        }}
                      </td>
                      <td v-if="b._userID">{{ b._userID.name }}</td>
                      <td v-else></td>
                      <td>{{ b.Rooms }}</td>
                      <td>
                        {{ b.bookingID.Adult }} A
                        <span v-if="b.Child"> {{ b.bookingID.Child }} C</span>
                        <span v-else>0 C </span>
                      </td>
                      <td>
                        <b-badge variant="success" v-if="b.status == true"
                          >Confirmed</b-badge
                        >
                        <b-badge variant="danger" v-if="b.status == false"
                          >Cancelled</b-badge
                        >
                      </td>
                      <td v-if="b.bookingID">
                        <router-link
                          :to="'/farm-retreat-bookings/' + b.bookingID._id"
                          target="_blank"
                          >View</router-link
                        >
                      </td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                </table>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-modal>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'BookingList',
  computed: {
    isDisable() {
      if (this.form.CheckInDate == '' || this.form.CheckOutDate == '') {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      fields: [
        '#',
        {
          label: 'Image',
          key: 'image',
        },
        {
          label: 'Farms',
          Key: '_projectID.name',
        },
        {
          label: 'Location',
          key: '_projectID.location',
        },
        'actions',
      ],

      disableBookButton: false,
      form: {
        projectID: '',
        projectCottageId: '',
        Room: 0,
        Adults: 0,
        Child: 0,
        CheckInDate: null,
        CheckOutDate: null,
      },
      booking: {
        _projectID: '',
        checkInDate: '',
        checkOutDate: '',
        _RoomID: '',
        roomCount: 0,
        _userID: '',
        Adults: '',
        Child: '',
        step: 1,
        roomCredits: '',
        availableCredits: 0,
        query: '',
        roomDetails: '',
        room: 0,
        days: 0,
        nights: 0,
        user: '',
        service_charge: 0,
        remarks: '',
        isBusy: false,
      },
      users: [],
      Projects: [],
      ProjectCottage: [],
      data: [],
      rooms: [],
      bookings: [],
      RoomOptions: [],
      AdultOptions: [],
      ChildOptions: [],
      isBusy: false,
    };
  },
  mounted() {
    this.getFarmRetreats();
    this.setInitialDate();
  },

  methods: {
    setInitialDate() {
      this.form.CheckInDate = moment().format('YYYY-MM-DD');
      this.form.CheckOutDate = moment().add(1, 'days').format('YYYY-MM-DD');
    
      this.form.CheckOutDate.setDate(this.form.CheckOutDate);
      // this.form.CheckOutDate.setDate(this.form.CheckOutDate.getDate() + 1);
    },

    dateDisabledCheckInDate(ymd, date) {
      let today = new Date();
      today.setDate(today.getDate() - 1);

      // Return `true` if the date should be disabled
      return date < today;
    },

    setCheckOutDate() {
      let date = new Date(this.form.CheckInDate);
      date.setDate(date.getDate() + 1);
      this.form.CheckOutDate = moment(date).format('YYYY-MM-DD');
      console.log(date);
    },

    dateDisabledCheckOutDate(ymd, date) {
      let today = new Date();
      today.setDate(today.getDate());
      if (this.form.CheckInDate) {
        today = new Date(this.form.CheckInDate);
      }
      // Return `true` if the date should be disabled
      return date < today;
    },

    setSelected(value) {
      console.log(value);
      this.booking.availableCredits = value.CurrentCredits;
      this.booking._userID = value._id;
    },
    roomSeletected(room) {
      console.log(room);
      this.booking.room = room;
      let form = this.form;
      this.booking.roomCount = this.form.Room;
      this.booking.query = this.form;
      this.booking.roomDetails = room;
      this.booking._RoomID = room._id;
      this.booking._projectID = room._projectResortID._projectID;
      this.booking._userID = room._userID;
      this.booking.Adults = form.Adults;
      this.booking.Child = form.Child;
      this.booking.checkInDate = form.CheckInDate;
      this.booking.checkOutDate = form.CheckOutDate;
      this.getUserList();
      //  this.booking.step = 2;
      // get the user list
      axios
        .post('/room-price-details', {
          room: room,
          form: form,
        })
        .then((resp) => {
          this.booking.roomCredits = resp.data.data.credits;
          this.booking.days = resp.data.data.days;
          this.booking.nights = resp.data.data.nights;
          this.booking.service_charge = resp.data.data.service_charge;
          this.booking.step = 2;
        });
    },
    close() {
      this.rooms = [];
      this.booking.step = 1;
      this.users = [];
      this.booking.remarks = '';
      this.booking.nights = 0;
      this.booking.days = 0;
      this.booking.service_charge = 0;
      this.booking.roomCredits = 0;
      this.booking.availableCredits = 0;
      this.booking.availableCredits = 0;
      this.booking.user = '';
    },
    bookRoom() {
      this.disableBookButton = true;
      axios.post('book-room', this.booking).then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          alert(resp.data.message);
          this.$refs['modal-view'].hide();
          window.location.reload();
        } else {
          alert(resp.data.message);
        }
        this.disableBookButton = false;
      });
    },
    getUserList() {
      axios.get('/users').then((resp) => {
        console.log(resp);
        this.users = resp.data.data;
      });
    },
    showRoomModal(data, index) {
      console.log(data, index);
      let form = this.form;

      axios
        .get('/resort-rooms', {
          params: {
            _projectID: data._projectID._id,
            CheckInDate: form.CheckInDate,
            CheckOutDate: form.CheckOutDate,
            Room: form.Room,
            Adults: form.Adults,
            Child: form.Child,
            cottageId: form.projectCottageId,
          },
        })
        .then((resp) => {
          console.log(resp);
          // booking
          // block
          // room
          this.rooms = resp.data.data.rooms;
          this.bookings = resp.data.data.booking;
          this.$refs['modal-view'].show();
        });
    },
    getProjectResult() {
      this.data = [];
      this.isBusy = true;
      axios.get('resort-rooms-search', { params: this.form }).then((resp) => {
        console.log(resp);
        this.data = resp.data.data;
        this.isBusy = false;
      });
    },
    FarmRetreatChange() {
      this.RoomOptions = this.AdultOptions = this.ChildOptions = [];
      this.form.projectCottageId = this.form.Room = this.form.Adults = this.form.Child = 0;

      axios.get('/project-resort-rooms/' + this.form.projectID).then((resp) => {
        console.log(resp.data.data);
        this.ProjectCottage = resp.data.data;
      });
    },
    getFarmRetreats() {
      axios.get('project-resorts').then((resp) => {
        console.log(resp);
        this.Projects = resp.data.data;
      });
    },
    AdultChange() {
      let child = this.form.Room * 4 - this.form.Adults;
      console.log(child);
      console.log('child');
      this.form.Child = '';
      this.ChildOptions = [];
      for (let i = 0; i <= child; i++) {
        this.ChildOptions.push({
          text: i,
          value: i,
        });
      }
    },
    setAdultCount() {
      let Adult = this.form.Room * 4;
      this.AdultOptions = [];
      this.form.Adults = '';
      for (let i = 0; i <= Adult; i++) {
        this.AdultOptions.push({
          text: i,
          value: i,
        });
      }
    },
    SetCottageCount() {
      let RoomCount = 0;
      this.ProjectCottage.forEach((cottage) => {
        if (cottage._id == this.form.projectCottageId)
          RoomCount = cottage.room_counts;
      });
      for (let i = 0; i <= RoomCount; i++)
        this.RoomOptions.push({ text: i, value: i });
    },
  },
};
</script>

<style scoped>
.dataTable {
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.dataTable thead {
  background-color: #e6e6e6;
  height: 51px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: 'Poppins', sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}

.dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.dataTable tr td {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}

.dataTable caption {
  border-radius: 10px 10px 0 0;
  height: 64px;
  width: 100%;
  background-color: #dcffd9;
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  padding-left: 27px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 25px;
}

.view-icon {
  color: #1da040;
  height: 20px;
  width: 30px;
}

.thead {
  background-color: #dcffd9;
}
</style>
